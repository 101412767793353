.landing{

}


.landing .landing-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}

.landing .landing-content .text{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    justify-content: space-around;
    background-color: #1c4c64;
    padding: 50px;
    padding-top: 127px;
    padding-bottom: 30px;
    background: rgb(28, 76, 100);
    background: linear-gradient(232deg, rgba(28, 76, 100, 1) 0%, rgba(71, 146, 184, 1) 100%);
}

.landing .landing-content .text .top{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.landing .landing-content .text .top h2{
    font-size: 19px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #ffe7be;
}

.landing .landing-content .text .top h1{
    font-size: 66px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;

}

.landing .landing-content .text .top p{
    color: #fff;
    font-size: 17px;
    direction: rtl;
    text-align: justify;
}

.landing .landing-content .text .bottom{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.landing .landing-content .text .bottom .socials{
    margin-bottom: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

.landing .landing-content .text .bottom .socials a{
    font-size: 19px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.landing .landing-content .text .bottom>a{
    font-size: 17px;
    color: #fff;
}

.landing .landing-content .text .bottom>a:nth-child(2){
    color: #b3b3b3;
}


.landing .landing-content .image{
    width: 50%;
    height: 100%;
    background-color: #000;
}

.landing .landing-content .image img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: top;
    opacity: 0.9;
}

@media(max-width:950px){
    .landing .landing-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 100vh;
        height: inherit;

    }

    .landing .landing-content .text {
        width: 100%;
    }
    .landing .landing-content .image img {
        width: 100%;
    
    }

    .landing .landing-content .text .top h1 {
        font-size: 43px;
        
    }

    .landing .landing-content .image {
        width: 100%;
        height: 100%;
        background-color: #000;
    }
    
}