.clients{
    background: rgb(28, 76, 100);
    background: linear-gradient(232deg, rgba(28, 76, 100, 1) 0%, rgba(71, 146, 184, 1) 100%);
    min-height: 65vh;
    padding-top: 60px;
    padding-bottom: 60px;
    z-index: 2;
    position: relative;
    overflow: hidden;
}



.clients .clients-header h1{
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    text-align: right;
    justify-content: flex-end;
}

.clients .clients-header h1 span{
    width: 90px;
    height: 3px;
    background-color: #fff;
    display: flex;
    margin-top: 37px;
}

.clients .clients-slider{
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.clients .clients-slider .buttons{
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
}

.clients .clients-slider .buttons button{
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 17px;
    border: none;
    outline: none;
    background-color: #000000a8;
}

.clients .clients-slider .swiper-slide{
    width: fit-content !important;
}

.clients .clients-slider .client{
    min-width: 150px;
}


.clients .clients-slider .client h2{
    color: #fff;
    font-family: "Reem Kufi", sans-serif;
}

@media(max-width:950px){
    .clients .clients-slider {
        min-height: 28vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .clients {
        background: rgb(28, 76, 100);
        background: linear-gradient(232deg, rgba(28, 76, 100, 1) 0%, rgba(71, 146, 184, 1) 100%);
        min-height: 50vh;
        padding-top: 60px;
        padding-bottom: 60px;
        z-index: 2;
        position: relative;
        overflow: hidden;
    }
}