.about{

}

.about .about-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
}
.about .about-content .image{
    width: 50%;
    height: 100%;
}

.about .about-content .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    filter: grayscale(1);
}

.about .about-content .text{
    text-align: right;
    padding: 50px;
    width: 50%;
}

.about .about-content .text h1{
    font-size: 50px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: self-start;
    gap: 10px;
}

.about .about-content .text h1 span{
    width: 90px;
    height: 3px;
    background-color: #000;
    display: flex;
    margin-top: 37px;
}



.about .about-content .text h2{
    direction: rtl;
    font-size: 22px;
    font-weight: 500;
    color: #000;
    margin-bottom: 34px;
}

.about .about-content .text p{
    color: #000;
    font-size: 17px;
    direction: rtl;
    text-align: justify;
    margin-bottom: 26px;
}

.about .about-content .text ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 30px !important;
}

.about .about-content .text ul li{
    width: 31%;
    font-size: 16px;
    font-weight: 800;
    font-family: "Reem Kufi", sans-serif;
}

@media(max-width:950px){
    .about .about-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        min-height: 100vh;
        height: inherit;
    }

    .about .about-content .text {
        text-align: right;
        padding: 50px;
        width: 100%;
    }

    .about .about-content .text h1 {
        font-size: 30px;
        
    }

    .about .about-content .text ul li {
        width: 30%;
        font-size: 16px;
        font-weight: 800;
        font-family: "Reem Kufi", sans-serif;
    }

    .about .about-content .image {
        width: 100%;
        height: 100%;
    }
}