.experience{

}

.experience .experience-content{
    
}

.experience .experience-content .header{
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.experience .experience-content .header h1{
    font-size: 50px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    text-align: right;
    justify-content: flex-end;
}

.experience .experience-content .header h1 span{
    width: 90px;
    height: 3px;
    background-color: #000;
    display: flex;
    margin-top: 37px;
}

.experience .experience-content .header p{
    width: 50%;
    font-size: 16px;
    text-align: right;
    direction: rtl;
    color: #000;
}


.experience .experience-content .all-exp .exp{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 300px;
}

.experience .experience-content .all-exp .exp .left{
    width: 75%;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 27px;
    height: 200px;
    padding-right: 44px;
}

.experience .experience-content .all-exp .exp .left h2{
    font-size: 21px;
    font-weight: 800;
    color: #377899;
}

.experience .experience-content .all-exp .exp .left p{
    width: 46%;
}


.experience .experience-content .all-exp .exp .right{
    width: 25%;
    text-align: right;
    height: 100%;
    height: 200px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
}

.experience .experience-content .all-exp .exp .right h3{
    font-family: "Reem Kufi", sans-serif;
    font-size: 29px;
    font-weight: 700;
    color: #000;
}

.experience .experience-content .all-exp .exp .right div{
    position: relative;
}

.experience .experience-content .all-exp .exp .right div span:nth-child(1){
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #0000004f;
    display: flex;
    background-color: transparent;
    left: -4px;
    top: 0;
    background-color: #fff;
}

.experience .experience-content .all-exp .exp .right div span:nth-child(2){
    display: flex;
    height: 327px;
    width: 1px;
    background-color: #00000042
}


@media(max-width:950px){
    .experience .experience-content .header {
        margin-top: 60px;
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
    }

    .experience .experience-content .header h1 {
        
        width: 100%;
    }

    .experience .experience-content .header p {
        width: 100%;
        font-size: 16px;
        text-align: right;
        direction: rtl;
        color: #000;
    }

    .experience .experience-content .all-exp .exp .left p {
        width: 100%;
    }

    .experience .experience-content .all-exp .exp .left {
        width: 70%;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 27px;
        height: 200px;
        padding-right: 44px;
    }

    .experience .experience-content .all-exp .exp .right h3 {
        font-family: "Reem Kufi", sans-serif;
        font-size: 24px;
        font-weight: 700;
        color: #000;
        width: 100%;
    }
}