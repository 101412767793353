.blog-header{
    height: 450px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.blog-header>img{
    width: 100%;
    position: absolute;
    inset: 0;
    object-fit: cover;
    height: 100%;
}

.blog-header>.overlay{
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: #0009;
    z-index: 2;
    position: absolute;
}

.blog-header .text{
    position: relative;
    z-index: 5;
}

.blog-header .text h1{
    font-size: 66px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    position: relative;
    z-index: 3;
}

.blog-header .text ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    color: #fff;
    gap: 33px;
}

.blog-header .text ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
    direction: rtl;
}


.blog-content .text{
    padding: 50px;
    margin-top: 60px;
}

.blog-content .text p{
    text-align: justify;
    direction: rtl;
    line-height: 28px;
    margin-bottom: 41px;
    font-size: 16px;
}


.blog-content .text h2{
    text-align: right;
    font-weight: 600;
    color: #000;
    font-size: 25px;
}

.blog-content .text img{
    width: 100%;
    height: 450px;
    object-fit: cover;
}


@media(max-width:950px){
    .blog-header .text h1{
        font-size: 47px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
        margin-bottom: 42px;
        font-family: "Reem Kufi", sans-serif;
        position: relative;
        z-index: 3;
    }
    .blog-content .text {
        padding: 0;
        margin-top: 60px;
    }
}