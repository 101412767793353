footer{
    height: 69px;
    background-color: #000;
}

footer .footer-content{
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

footer .footer-content h1{
    margin: 0;
    font-size: 15px;
    color: #fff;
}

footer .footer-content h1 span{
    color: #facc85;
}