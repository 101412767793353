.page-header{
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.page-header img{
    position: absolute;
    width: 104%;
    height: 108%;
    object-fit: cover;
    filter: blur(15px);
    top: -14px;
    left: -18px;
}



.page-header .text h1{
    font-size: 66px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    position: relative;
    z-index: 3;
}


.story-content{
    margin-top: 63px;
}



.story-content .story-image img{
    width: 100%;
    height: 600px;
    object-fit: contain;
}

.story-content .story-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.story-content .story-text ul{
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    gap: 26px;
}

.story-content .story-text ul li{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.story-content .story-text ul li span{
    font-weight: 800;
}

.story-content .story-text .line{
    width: 100%;
    display: flex;
    height: 1px;
    background-color: #00000026;
    margin-top: 15px;
    margin-bottom: 15px;
}

.story-content .story-text .share{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    width: 100%;
}

.story-content .story-text .share span{
    font-weight: 600;
}

.story-content .story-text .share div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    color: #000;
    font-size: 16px;
}

.story-content .story-text .share div a{
    color: #000;
}

.story-content .story-text>a{
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 18px;
    background-color: #000000;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 500;
    margin-right: 50px;
}

.story-content .story-text .text{
    padding: 50px;
}


.story-content .story-text .text h1{
    font-size: 40px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    position: relative;
    z-index: 3;
}

.story-content .story-text .text p{
    text-align: justify;
    direction: rtl;
    line-height: 26px;
}


@media(max-width:950px){
    .story-content .story-text ul{
        padding: 0;
        margin-top: 30px !important;
        
    }
    

    .story-content .story-text .text{
        padding: 0;
    }

    
    .story-content .story-text .share{
        padding: 0;

    }
    

    .story-content .story-text>a{
        width: 100%;
        margin-right: 0;

        margin-bottom: 20px;
    }
    
}