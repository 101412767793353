nav{
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 151;
}

nav .nav-content{
    
}

nav .nav-content button{
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 35px;
}


.float-nav{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ab;
    z-index: 100;
    transition: 0.5s;
    user-select: none;
    opacity: 0;
    pointer-events: none;

}

.float-nav.active{
    user-select: inherit;
    opacity: 1;
    pointer-events: inherit;

}

.float-nav .float-nav-content{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 108px;
}

.float-nav .float-nav-content button{
    margin-bottom: 36px;
    font-size: 45px;
    color: #fff;
    background-color: transparent;
    outline: none;
    border: none;
}

.float-nav .float-nav-content ul{
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
}


.float-nav .float-nav-content ul li{
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff21;
    width: 100%;
}

.float-nav .float-nav-content ul li a{
    font-size: 38px;
    color: #fff;
    font-family: "Reem Kufi", sans-serif;
    cursor: pointer;
}