.contact{
    background: rgb(28, 76, 100);
    background: linear-gradient(232deg, rgba(28, 76, 100, 1) 0%, rgba(71, 146, 184, 1) 100%);
    min-height: 80vh;
    padding-top: 60px;
    padding-bottom: 60px;
}


.contact .contact-form form{
    min-height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.contact .contact-form form .input{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contact .contact-form form .input input{
    width: 100%;
    height: 55px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    border-bottom: 1px solid #fff;
    direction: rtl;
    padding-right: 15px;
}

.contact .contact-form form .input textarea{
    width: 100%;
    height: 168px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    border-bottom: 1px solid #fff;
    direction: rtl;
    padding-top: 15px;
    padding-right: 15px;
}

.contact .contact-form form .input input::placeholder{
    color: #fff;
}

.contact .contact-form form .input textarea::placeholder{
    color: #fff;

}

.contact .contact-form form button{
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 18px;
    background-color: #000000;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 500;
}

.contact .contact-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
}


.contact .contact-text h1{
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    text-align: right;
    justify-content: flex-end;
}

.contact .contact-text ul{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 53px;
}


.contact .contact-text ul li a{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-size: 16px;
}

.contact .contact-text .socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #fff;
}


.contact .contact-text .socials a{
    color: #fff;
    font-size: 25px;
}

@media(max-width:950px){
    .contact .row {
        flex-direction: column-reverse;
        gap: 50px;
    }

    .contact .contact-text ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        margin-bottom: 25px !important;
    }
}