.blogs{
    background-image: url("../images/main (1).jpg");
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    position: relative;
}



.blogs>.overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #2d2d2db0;
}


.blogs .header{
    position: relative;
    z-index: 1;
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.blogs .header .buttons{
    
    display: flex;
    flex-direction: row;
    gap: 15px;
}


.blogs .header .buttons button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 50%;
    color: #ffddac;
    border: 1px solid;
}



.blogs .header .text{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.blogs .header .text p{
    color: #fff;
    text-align: right;
    direction: rtl;
}

.blogs .header .text a{
    color: #ffe7be;
}

.blogs .header h1{
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    text-align: right;
    justify-content: flex-end;
}

.blogs .header h1 span{
    width: 90px;
    height: 3px;
    background-color: #fff;
    display: flex;
    margin-top: 37px;
}

.blogs .blogs-slider{
    position: relative;
    z-index: 1;
    height: 73vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.blogs .swiper-slide{
    width: fit-content !important;
}


.blogs .blog{
    width: 394px;
    color: #fff;
    min-height: 350px;
    padding-bottom: 10px;
    border-bottom: 2px solid #ffe7be;
}


.blogs .blog h1{
    font-size: 29px;
    color: #fff;
    font-weight: 700;
    font-family: "Reem Kufi", sans-serif;
    text-align: right;
}


.blogs .blog div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 52px;
    margin-top: 24px;
}


.blogs .blog div h2{
    font-size: 16px;
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.blogs .blog div h2 i{
    color: #ffe7be;
}


.blogs .blog p{
    text-align: right;
    direction: rtl;
}


@media(max-width:950px){
    .blogs .header {
        position: relative;
        z-index: 1;
        padding-top: 60px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .blogs .header h1 {
        width: 100%;
    }
    .blogs .header .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 30px;
    }

    .blogs .swiper-slide {
        width: 100% !important;
    }

    .blogs .blog {
        width: 100%;
    }


    .blogs .header .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}