.work{
    background: rgb(28, 76, 100);
    background: linear-gradient(232deg, rgba(28, 76, 100, 1) 0%, rgba(71, 146, 184, 1) 100%);
    min-height: 100vh;
}

.work .work-content{
    
}

.work .work-content .work-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 47px;
}

.work .work-content .work-header .left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 76px;
}

.work .work-content .work-header .left .buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}


.work .work-content .work-header .left .buttons button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 50%;
    color: #ffddac;
    border: 1px solid;
}

.work .work-content .work-header .left .desc{
    text-align: right;
    font-size: 16px;
    color: #fff;
    direction: rtl;
}

.work .work-content .work-header .left .desc p{
    
}

.work .work-content .work-header .left .desc a{
    color: #ffe7be;
}

.work .work-content .work-header h1{
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    margin-bottom: 42px;
    font-family: "Reem Kufi", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    text-align: right;
    justify-content: flex-end;
}

.work .work-content .work-header h1 span{
    width: 90px;
    height: 3px;
    background-color: #fff;
    display: flex;
    margin-top: 37px;
}


.work .work-content .work-slider{
    margin-top: 30px;
}

.work .work-content .work-slider .swiper-slide{
    width: fit-content;
}

.work .work-content .work-slider .swiper-slide a{
    width: 283px;
    display: flex;
}

.work .work-content .work-slider .swiper-slide .work-ele{
    width: 294px;
    position: relative;
}

.work .work-content .work-slider .swiper-slide .work-ele img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    height: 450px;
}

.work .work-content .work-slider .swiper-slide .work-ele .overlay{
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffffa3;
    transition: 0.5s;
    opacity: 0;
}

.work .work-content .work-slider .swiper-slide .work-ele:hover .overlay{
    opacity: 1;
}

.work .work-content .work-slider .swiper-slide .work-ele .overlay h1{
    font-size: 31px;
    font-weight: 700;
    font-family: "Reem Kufi", sans-serif;
    color: #000;
    text-align: center;
}

.work .work-content .work-slider .swiper-slide .work-ele .overlay h2{
    font-size: 18px;
    direction: rtl;
    color: #131313;
}

@media(max-width:950px){
    .work .work-content .work-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        padding-top: 45px;
        padding-bottom: 47px;
    }

    .work .work-content .work-header h1 {
        font-size: 40px;
        
    }

    .work .work-content .work-header .left {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 76px;
    }

    .work .work-content .work-slider .swiper-slide a {
        width: 100%;
        display: flex;
        height: 550px;
    }

    .work .work-content .work-slider .swiper-slide .work-ele {
        width: 100%;
        position: relative;
    }

    .work .work-content .work-slider .swiper-slide {
        width: 100% !important;
    }

    .work {
        min-height: 100vh;
    }
}